<template>
  <v-banner>
    <p class="mb-0">
      <b>{{ title }}</b>
    </p>

    <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
      <slot :name="name" v-bind="item" />
    </template>
  </v-banner>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
