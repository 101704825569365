<template>
  <v-container v-if="!_.isEmpty(financialProductEditable)">
    <!-- Banner header -->
    <Banner :title="'Editar producto financiero'" />

    <!-- Dialog edit financial product -->
    <v-card class="my-8 pa-1">
      <v-card-text class="pa-5">
        <v-form ref="formEditFinancialProduct" v-model="formEditFinancialProductValid">
          <v-text-field v-model="financialProductEditable.name" label="Nombre" :rules="formRules.textRules" />
          <DatePicker
            :parent_model="financialProductEditable.expiration_date"
            label="Fecha de expiración"
            :min="dateNextDayFormated()"
            @change="financialProductEditable.expiration_date = $event"
          />

          <p class="mb-2 mt-5 text-subtitle-1">Disponible en:</p>
          <v-row>
            <v-col v-show="offices.some((e) => e.country.id === country.id)" v-for="(country, i) in countries" :key="i" cols="12" md="3">
              <v-card>
                <v-toolbar color="primary" dense>
                  {{ country.name }}
                </v-toolbar>
                <v-card-text>
                  <v-treeview
                    v-model="selected[country.id]"
                    v-if="countryItems.length > 0"
                    :items="countryItems.filter((e) => e.id === country.id)"
                    open-all
                    selectable
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text :disabled="!permissionsActions.edit.includes(user.role)" @click="openDialogConfirmEditFinancialProduct"> Editar </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog confirm edit product -->
    <DialogBox :model="dialogConfirmEditFinancialProduct" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>Editar producto</span>
      </template>
      <template slot="content">
        <b>¿Estás seguro de editar el producto?</b>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogConfirmEditFinancialProduct = false">Cerrar</v-btn>
        <v-btn text @click="edit()">Editar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Banner from "@/components/elements/Banner";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";

export default {
  name: "Edit-product",
  components: {
    Banner,
    DialogBox,
    DatePicker
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      countryItems: [],
      selected: {},
      permissionsActions: {
        edit: ["SUPERADMIN"]
      },

      // Fields of financial product
      financialProduct: {},
      financialProductEditable: {},
      formEditFinancialProductValid: false,

      // Dialog confirm edit financial product
      dialogConfirmEditFinancialProduct: false
    };
  },
  watch: {
    financialProducts() {
      this.formatFields();
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      countries: (state) => state.locations.countries,
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices,
      financialProducts: (state) => state.products.financialProducts
    }),
    _() {
      return _;
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.$store.dispatch("products/getFinancialProducts");

    if (!this.financialProducts.find((e) => e.id === this.$route.params.id)) this.$router.push("/productos"); // Push back if id does not exist

    await this.$store.dispatch("locations/getCountries");
    await this.$store.dispatch("partners/getPartners");
    await this.$store.dispatch("offices/getOffices");
    await this.formatFields();
    this.getCountryItems();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    async formatFields() {
      const financialProductFromListener = this.financialProducts.find((e) => e.id === this.$route.params.id);
      this.financialProduct = financialProductFromListener;
      this.financialProductEditable = this._.cloneDeep(financialProductFromListener);

      // Format offices
      for (let i = 0; i < this.financialProductEditable?.requirements?.offices.length; i++) {
        const officeId = this.financialProductEditable.requirements.offices[i];
        const productOffice = await this.$store.dispatch("offices/getOffice", { filter: { id: officeId } });

        this.selected[productOffice.country.id]
          ? this.selected[productOffice.country.id].push(productOffice.id)
          : (this.selected[productOffice.country.id] = [productOffice.id]);
      }
    },

    // Get array of partners and offices for treeview
    getCountryItems() {
      for (let i = 0; i < this.countries.length; i++) {
        const partners = this.partners.filter((partner) => partner.country.id === this.countries[i].id);
        const data = {
          id: this.countries[i].id,
          name: this.countries[i].name,
          disabled: this.isUncompleteCountry(this.countries[i]),
          children: []
        };

        for (let k = 0; k < partners.length; k++) {
          const partner = partners[k];
          const hasOffices = this.offices.some((e) => e.partner.id === partner.id);

          if (hasOffices) {
            data.children.push({
              id: partner.id,
              name: partner.name,
              children: []
            });

            for (const office of this.offices.filter((e) => e.partner.id === partner.id)) {
              const partnerChildrenPos = data.children.findIndex((partnerChildren) => partnerChildren.id === partner.id);
              data.children[partnerChildrenPos].children.push({
                id: office.id,
                name: office.name
              });
            }
          }
        }

        if (data.children.length > 0) this.countryItems.push(data);
      }
    },

    openDialogConfirmEditFinancialProduct() {
      if (this.$refs.formEditFinancialProduct.validate()) {
        let isSomeOfficeSelected = false;
        const selectedOffices = this._.values(this.selected);
        for (const office of selectedOffices) {
          if (office.length > 0) isSomeOfficeSelected = true;
        }
        if (this.formEditFinancialProductValid && isSomeOfficeSelected) this.dialogConfirmEditFinancialProduct = true;
      }
    },

    // Return if a country has not all necessary information
    isUncompleteCountry(country) {
      return !country.arrear_vat && !country.vat && !country.currency;
    },

    // Edit financial product
    async edit() {
      if (this.$refs.formEditFinancialProduct.validate()) {
        let financialProductEditableFormattedData = { ...this.financialProductEditable };
        const valuesOffices = [];
        let unionOffices = [];

        for (const values of this._.values(this.selected)) {
          valuesOffices.push(values);
          unionOffices = this._.union(unionOffices, values);
        }

        financialProductEditableFormattedData.requirements.offices = unionOffices;
        if (financialProductEditableFormattedData.expiration_date)
          financialProductEditableFormattedData.expiration_date = this.formatDateToISO8601(this.financialProductEditable.expiration_date);

        // Check if there is no change in partner fields
        const notChanges = this._.isEqual(financialProductEditableFormattedData, this.financialProduct);
        if (!notChanges) {
          const data_to_change = {
            id: financialProductEditableFormattedData.id,
            name: financialProductEditableFormattedData.name,
            requirements: {
              offices: financialProductEditableFormattedData.requirements.offices
            }
          };

          if (financialProductEditableFormattedData.expiration_date)
            data_to_change.expiration_date = financialProductEditableFormattedData.expiration_date;
          console.log(data_to_change);
          await this.$store.dispatch("products/editFinancialProduct", data_to_change);
        }

        this.formatFields();
        this.dialogConfirmEditFinancialProduct = false;
      }
    }
  }
};
</script>
